@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 1px;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

/* Telephone Input */
.react-tel-input input {
  width: 100% !important;
}

.preview_applicant_table,
.preview_applicant_table td,
.preview_applicant_table th,
.preview_signature_table,
.preview_signature_table td,
.preview_signature_table th,
.preview_claim_table,
.preview_claim_table td,
.preview_claim_table th,
.preview_leave_table,
.preview_leave_table td,
.preview_leave_table th {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid black;
}

.preview_applicant_table td,
.preview_applicant_table th {
  padding: 5px;
}

.preview_applicant_table .value {
  margin-left: 10px;
  font-weight: bold;
}

.preview_applicant_table,
.preview_claim_table,
.preview_leave_table {
  font-size: 12px;
}

.preview_claim_table th
 {
  padding: 5px;
  background-color: #f2f2f2;
}

.preview_leave_table th {
  padding: 5px;
  background-color: #d3d3d3;
}

.preview_claim_table td,
.preview_leave_table td {
  padding: 5px;
  font-weight: bold;
}

.preview_signature_table {
  font-size: 10px;
}

.preview_signature_table td {
  padding: 5px;
}


/* List Style */
ol,
ul {
  font-size: 10px;
  line-height: 2;
}

ul {
  list-style: none;
  /* Remove default bullets */
}

ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}

a.desktop.active div {
  color: rgba(24,144,255);
  background-color: rgba(24,144,255, 0.1) !important;
  border-right: 5px solid rgba(24,144,255);
  font-weight: 500;
}

a.mobile div {
  font-size: 15px;
  padding: 0 10px;
}

a.mobile.active div {
  color: rgba(24,144,255);
  font-weight: 500;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}